.App {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #7bbde0;
	color: #333;
}

.wrapper {
	max-width: 300px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.desc {
	text-align: center;
}

.social {
	display: flex;
	margin-top: 1rem;
}

.social a {
	font-size: 2rem;
	color: #333;
	padding: 1rem;
	transition: 0.2s;
}

.social a:hover {
	transform: scale(1.1);
}
