@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400&display=swap");

html,
body,
#root {
	height: 100%;
}
body {
	margin: 0;
	font-family: "Roboto Mono", sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
