.link {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	background: rgba(255, 255, 255, 0.2);
	margin-top: 1rem;
	transition: 0.2s;
	min-width: 300px;
	color: #333;
	text-decoration: none !important;
	border-radius: 100px;
}

.link:hover {
	cursor: pointer;
	transform: scale(1.05);
}
